import { __assign } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../theme';
import { Grid } from '@mui/material';
import { Field, useForm } from 'react-final-form';
import { TravellerFields } from '../../types';
import Input from '../../../BaseComponents/Input';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { useTranslation } from 'react-i18next';
import SexSelector from '../../../BaseComponents/SexSelector';
import { DateMaskedInput } from '../../../DateMaskedInput/DateMaskedInput';
var PersonalInfoSection = function () {
    var css = useTheme('TravellerForm').PersonalInfoSection;
    var tDocumentCardForm = useTranslation('DocumentCardForm').t;
    var tTraveller = useTranslation('Traveller').t;
    var isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    var variant = isMobile ? 'underline' : 'labelIn';
    var form = useForm();
    return (React.createElement("div", { className: css.personalInfoSection },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: TravellerFields.BirthDate, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(Input, __assign({}, input, { className: css.input, variant: variant, label: "".concat(tTraveller('birthDate'), "*"), error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error), InputProps: {
                                inputComponent: DateMaskedInput
                            }, onChange: function (event) {
                                input.onChange(event);
                                form.mutators.fixEmptyFieldMutator({ fieldName: TravellerFields.BirthDate });
                            } })));
                    } })),
            React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                React.createElement(Field, { name: TravellerFields.Gender, render: function (_a) {
                        var input = _a.input, meta = _a.meta;
                        return (React.createElement(SexSelector, { activeSex: input.value, onChange: function (value) {
                                input.onChange(value);
                            }, error: meta.error && meta.touched, helperText: meta.touched && tDocumentCardForm(meta.error) }));
                    } })))));
};
export default PersonalInfoSection;
