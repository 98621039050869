import * as React from 'react';
import { CartService } from '../../Cart/types';
import { GdsServiceProductStatus, OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import BaggageIcon from '../../Cart/BaggageIcon/BaggageIcon';
import { useConfig } from '../../context';
import { isPetBaggage } from '../../Checkout/utils';
import CommonSummary from './CommonSummary';
import { getTotalPrice } from '../../utils';
import { getBaggageName } from '../utils';
const BaggageSummary = props => {
    const { global: { servicesAvailable: { animal: availableAnimal } } } = useConfig();
    const onServiceDeleteHandler = (travellerId, serviceId, segmentId, allowedSegments, allSegments, count) => {
        var _a;
        (_a = props.onServiceDelete) === null || _a === void 0 ? void 0 : _a.call(props, CartService.Baggage, {
            passengerId: travellerId,
            serviceId,
            segmentId,
            allSegments,
            allowedSegments,
            count
        });
    };
    const itemFactory = ({ selectedService, relatedOrderService, travellerId, key, groupType }) => {
        let name;
        let resultCount;
        let price;
        let included = selectedService.confirmedCount === selectedService.count;
        switch (groupType) {
            case 'all':
                resultCount = selectedService.count;
                price = getTotalPrice(selectedService.products, product => product.price);
                name = getBaggageName(relatedOrderService, selectedService.count);
                price.amount *= props.flight.segmentGroups.length;
                break;
            case 'booked':
                resultCount = selectedService.count - selectedService.confirmedCount;
                price = getTotalPrice(selectedService.products.filter(product => product.status === GdsServiceProductStatus.Booked), product => product.price);
                name = getBaggageName(relatedOrderService, selectedService.count - selectedService.confirmedCount);
                break;
            case 'confirmed':
                resultCount = selectedService.confirmedCount;
                name = getBaggageName(relatedOrderService, selectedService.confirmedCount);
                price = getTotalPrice(selectedService.products.filter(product => product.status === GdsServiceProductStatus.Ticketed), product => product.price);
                included = true;
                break;
        }
        const dCount = selectedService.count - selectedService.confirmedCount;
        const count = selectedService.count - dCount;
        const service = {
            id: selectedService.serviceId,
            segment: key,
            count: resultCount,
            name: name,
            price: price,
            included: included,
            icon: !props.isAnimalBaggage ? React.createElement(BaggageIcon, { baggageType: relatedOrderService.baggageType }) : null,
            onDelete: groupType === 'confirmed'
                ? null
                : () => onServiceDeleteHandler(travellerId, selectedService.serviceId, key, relatedOrderService.allowedSegments, key === 'allSegments', count)
        };
        if (props.showServicesOnCurrentSegment) {
            if (props.segmentId === key) {
                return service;
            }
            else {
                return null;
            }
        }
        return service;
    };
    return (React.createElement(CommonSummary, { travellersById: props.travellersById, flight: props.flight, groupType: OrderAdditionalServiceGdsServiceServiceType.Baggage, services: props.services, travellers: props.travellers, onServiceDelete: () => null, segmentId: props.segmentId, serviceOptionGroupFactory: ({ items, travellerId }) => {
            const itemsBySegmentMap = new Map();
            items.forEach(item => {
                var _a;
                const key = item.segment;
                itemsBySegmentMap.set(key, [...((_a = itemsBySegmentMap.get(key)) !== null && _a !== void 0 ? _a : []), item]);
            });
            return [...itemsBySegmentMap.entries()].map(([key, services]) => {
                var _a;
                const isAllSegments = key === 'allSegments';
                return {
                    items: services,
                    allSegments: isAllSegments,
                    headerType: 'travellerName',
                    traveller: props.travellersById.get(travellerId),
                    direction: !isAllSegments
                        ? (_a = props.flight.segments.find(segment => segment.segment.id === key)) === null || _a === void 0 ? void 0 : _a.segment
                        : undefined
                };
            });
        }, includedOptionItemFactory: ({ selectedService: included, key }) => {
            if (!props.isAnimalBaggage) {
                const baggageService = included.service;
                return {
                    id: included.serviceId,
                    segment: key,
                    count: included.count,
                    name: getBaggageName(baggageService, included.count),
                    included: true,
                    icon: React.createElement(BaggageIcon, { baggageType: included.service['baggageType'] })
                };
            }
            return null;
        }, serviceOptionItemFactory: ({ selectedService, key, travellerId }) => {
            var _a, _b, _c;
            const relatedOrderService = (_c = (_b = (_a = props.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(service => service.id === selectedService.serviceId);
            if (selectedService.confirmedCount && selectedService.count !== selectedService.confirmedCount) {
                return [
                    itemFactory({ selectedService, travellerId, key, relatedOrderService, groupType: 'confirmed' }),
                    itemFactory({ selectedService, travellerId, key, relatedOrderService, groupType: 'booked' })
                ];
            }
            return itemFactory({ selectedService, travellerId, key, relatedOrderService, groupType: 'all' });
        }, serviceFilterPredicate: service => {
            const baggageService = service;
            if (availableAnimal) {
                const petBaggage = isPetBaggage(baggageService);
                return props.isAnimalBaggage ? petBaggage : !petBaggage;
            }
            return true;
        } }, children => props.children(children)));
};
export default BaggageSummary;
