import * as React from 'react';
import { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { useTheme } from '../../theme';
import { initI18n, useIsMobile } from '../../utils';
import { useRetrieveBookingValidation } from '../hooks';
import { useRetrieveBooking } from './RetrieveBooking.hook';
import Input from '../../BaseComponents/Input';
import Button from '../../BaseComponents/Button';
import Tooltip from '../../Tooltip';
import ClarifyDocumentModal from './ClarifyDocumentModal/ClarifyDocumentModal';
import Notification from '../../Notification';
import BookingPreview from '../../BookingPreview';
import { InfoQuestion } from '../../Icons';
initI18n('RetrieveBooking');
export var Mode;
(function (Mode) {
    Mode[Mode["Checkout"] = 0] = "Checkout";
    Mode[Mode["Checkin"] = 1] = "Checkin";
})(Mode || (Mode = {}));
const RetrieveBooking = props => {
    const { mode, className, title, initialId, initialSecret, idTitle, secretTitle, withBookingPreview, onHideOrderPreview } = props;
    const { t, i18n } = useTranslation('RetrieveBooking');
    const { RetrieveBooking: css } = useTheme('RetrieveBooking');
    const isMobile = useIsMobile();
    const showOrderPreview = !!props.orderPreview;
    const model = useRetrieveBooking(props);
    const { lastNameValidation, ticketNumberValidation, validateByOrderNumber, ticketNumberFormat } = useRetrieveBookingValidation();
    const inputVariant = isMobile ? 'underline' : 'labelIn';
    const renderTooltip = useCallback((meta) => {
        if (meta.data.warning && mode === Mode.Checkin) {
            return (React.createElement(Tooltip, { tooltipClassName: css.tooltip, title: t(meta.data.warning), open: true },
                React.createElement("div", { className: css.tooltip__icon, "data-mode": "checkin" }, InfoQuestion)));
        }
        return (React.createElement(Tooltip, { title: t('You can find security code in your booking confirmation email') },
            React.createElement("div", { className: css.tooltip__icon, "data-mode": mode === Mode.Checkin ? 'checkin' : 'checkout' }, InfoQuestion)));
    }, [mode]);
    return (React.createElement("div", { className: cn(css.container, className, {
            [css.container_checkin]: mode === Mode.Checkin
        }) },
        React.createElement("div", { className: css.title }, title !== null && title !== void 0 ? title : t('Retrieve booking')),
        React.createElement(ClarifyDocumentModal, { searchParams: model.searchParams, onSubmit: model.handleClarifySubmit, open: model.isClarityDocumentOpen, onClose: model.closeClarityDocument }),
        React.createElement(Form, { onSubmit: model.handleSubmit }, ({ handleSubmit, valid }) => (React.createElement("form", { className: css.form, onSubmit: handleSubmit },
            React.createElement("div", { className: css.form__row },
                React.createElement(Field, { name: "secret", initialValue: initialSecret, validate: (value, allValues, meta) => {
                        if (!value) {
                            return t('Required field');
                        }
                        if (mode === Mode.Checkin) {
                            return lastNameValidation(value);
                        }
                    }, render: ({ input, meta }) => (React.createElement(React.Fragment, null,
                        React.createElement(Input, Object.assign({}, input, { className: css.form__input, disabled: model.isLoading || (withBookingPreview && showOrderPreview), label: secretTitle || t('Security code'), variant: inputVariant, error: model.isOrderNotFoundError ||
                                model.isBlockedForPayment ||
                                (((meta.modified && meta.touched) || meta.submitFailed) &&
                                    (!!meta.error || !!meta.submitError)), helperText: (model.isOrderNotFoundError && t('Order not found')) ||
                                (model.isBlockedForPayment &&
                                    t('The order is being paid at the moment, please try again later')) ||
                                (((meta.modified && meta.touched) || meta.submitFailed) &&
                                    (meta.error || meta.submitError)) })))) })),
            React.createElement("div", { className: css.form__row },
                React.createElement(Field, { name: "id", initialValue: initialId, parse: ticketNumberFormat, validate: (value) => {
                        if (!value) {
                            return t('Required field');
                        }
                        if (mode === Mode.Checkin) {
                            return ticketNumberValidation(value);
                        }
                        if (mode === Mode.Checkout) {
                            if (!ticketNumberValidation(value) || !validateByOrderNumber(value)) {
                                return undefined;
                            }
                            else {
                                return ticketNumberValidation(value) || validateByOrderNumber(value);
                            }
                        }
                    }, render: ({ input, meta }) => (React.createElement(React.Fragment, null,
                        React.createElement(Input, Object.assign({}, input, { className: css.form__input, disabled: model.isLoading || (withBookingPreview && showOrderPreview), label: idTitle || t('Booking ID'), variant: inputVariant, helperText: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                (meta.error || meta.submitError), error: ((meta.modified && meta.touched) || meta.submitFailed) &&
                                (!!meta.error || !!meta.submitError) })),
                        renderTooltip(meta))) })),
            model.codeIsWrong && model.isErrorNotification && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, contextClassName: css.notification_context, textClassName: css.notification__text, infoMessage: {
                    text: t('Booking not found. Please, check the information entered or use your e-ticket number for identification'),
                    type: InfoMessageTypeEnum.Warning,
                    code: 'warning'
                }, type: "context" })),
            model.isOnlyAirportCheckin && (React.createElement(Notification, { status: InfoMessageTypeEnum.Warning, contextClassName: css.notification_context, textClassName: css.notification__text, infoMessage: {
                    text: t('Online check-in is not available. Check-in only at the airport'),
                    type: InfoMessageTypeEnum.Warning,
                    code: 'warning'
                }, type: "context" })),
            withBookingPreview && (model.isLoading || props.orderPreview) ? (React.createElement(BookingPreview, { redirectViaHistory: true, order: props.orderPreview, onClearOrder: onHideOrderPreview, isLoading: model.isLoading })) : (React.createElement("div", { className: cn(css.form__row, css.form__row_footer) },
                model.isCheckinMode && i18n.exists('RetrieveBooking:checkinHint') && (React.createElement("div", { className: css.checkinHint }, t('RetrieveBooking:checkinHint'))),
                React.createElement(Button, { className: cn(css.button, {
                        [css.button_disabled]: !valid
                    }), type: "submit", disabled: !valid, isLoading: model.isLoading }, t('Open')))))))));
};
export default RetrieveBooking;
