import * as React from 'react';
import CartItemOptionAdapter from '../../Cart/CartItemOptionAdapter/CartItemOptionAdapter';
import { groupServices } from '../../Cart/utils';
import ServiceSummaryDivider from '../components/ServiceSummaryDivider/ServiceSummaryDivider';
const CommonSummary = props => {
    const summaryGroups = React.useMemo(() => {
        const travellerItems = [];
        props.travellers.forEach(traveller => {
            var _a;
            const groupedService = groupServices(props.services, traveller, traveller.id, props.groupType, props.flight.segments, props.serviceFilterPredicate);
            const items = [];
            (_a = Object.keys(groupedService[traveller.id] || {})) === null || _a === void 0 ? void 0 : _a.forEach(key => {
                var _a;
                (_a = groupedService[traveller.id][key].included) === null || _a === void 0 ? void 0 : _a.forEach(included => {
                    const includedItem = props.includedOptionItemFactory({
                        selectedService: included,
                        key,
                        travellerId: traveller.id
                    });
                    if (props.includedOptionItemFactory && includedItem) {
                        items.push(includedItem);
                    }
                });
                if (groupedService[traveller.id][key].additional) {
                    groupedService[traveller.id][key].additional.forEach(selectedService => {
                        const newItems = props.serviceOptionItemFactory({
                            selectedService,
                            key,
                            travellerId: traveller.id
                        });
                        if (!!newItems) {
                            if (Array.isArray(newItems)) {
                                items.push(...newItems);
                            }
                            else {
                                items.push(newItems);
                            }
                        }
                    });
                }
            });
            if (!!items.length) {
                const newGroup = props.serviceOptionGroupFactory({ items, travellerId: traveller.id });
                if (Array.isArray(newGroup)) {
                    travellerItems.push(...newGroup);
                }
                else {
                    travellerItems.push(newGroup);
                }
            }
        });
        return travellerItems;
    }, [props.services, props.travellers, props.flight, props.segmentId]);
    const content = React.useMemo(() => {
        return !!summaryGroups.length ? (React.createElement(ServiceSummaryDivider, null, summaryGroups.map(meal => {
            return React.createElement(CartItemOptionAdapter, { option: meal, key: meal.header });
        }))) : null;
    }, [summaryGroups]);
    return props.children(content);
};
export default CommonSummary;
