import { __awaiter } from "tslib";
import * as React from 'react';
import { useContext } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de, enGB, kk, ru, hy, ka, tr, cs, be } from 'date-fns/locale';
import { format as originalFormat, parseISO, formatDistance as originalFormatDistance } from 'date-fns';
import * as Sentry from '@sentry/react';
import { TravellerFieldEnum, GdsServiceProductStatus, SsrStatus, Gender } from '@websky/graphql';
import { Locale } from './types';
import { Currency } from './enums';
import { getCurrency, getLocaleFromLocalStorage, getShowTranslationKeysFromLocalStorage, setLocaleToLocalStorage } from './cache';
import memes from './memes';
import { useMediaQuery } from 'react-responsive';
import dataLayerAnalytics from './DataLayerAnalytics/DataLayerAnalytics';
import tg from './Locales/tg';
dataLayerAnalytics.init({
    currency: getCurrency()
});
export const STAY_LOGGED_IN = 'STAY_LOGGED_IN';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_REGEXP = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
export const SHOW_RECAPTCHA = 'SHOW_RECAPTCHA';
export const CORPORATE_CARD = 'CORPORATE_CARD';
export const TABLET_MIN_WIDTH = 1024;
export const TABLET_MIDDLE_WIDTH = 768;
export const MOBILE_MIN_WIDTH = 600;
export const LOCALES = new Set([
    Locale.Russian,
    Locale.English,
    Locale.German,
    Locale.Kazakh,
    Locale.French,
    Locale.Chinese,
    Locale.Armenian,
    Locale.Georgian,
    Locale.Turkish,
    Locale.Tajik,
    Locale.Czech,
    Locale.Belarusian
]);
export const convertICULocale = (locale) => {
    /**
     * ICU format locale for kazakh is kk, but on tickets.scat.kz but the site uses kz,
     * in this function we convert it
     */
    const localeLangMap = new Map([['kk', 'kz']]);
    if (!localeLangMap.has(locale)) {
        return locale;
    }
    else {
        return localeLangMap.get(locale);
    }
};
export const getInfo = (options = {}) => ({
    info: Object.assign({ inline: true, source: true, maxPropsIntoLine: 1, maxPropArrayLength: 2 }, options)
});
export const isDemo = document.location.href.includes('demo.websky.tech');
export const createWithThemeDecorator = (Consumer) => {
    return (Component) => {
        const NewComponent = class extends React.Component {
            render() {
                return React.createElement(Consumer, null, context => React.createElement(Component, Object.assign({}, this.props, { theme: context })));
            }
        };
        NewComponent.displayName = Component.displayName;
        return NewComponent;
    };
};
export const createTheme = (defaultTheme) => {
    const Context = React.createContext(defaultTheme);
    return {
        Context: Context,
        Consumer: Context.Consumer,
        Provider: Context.Provider,
        withTheme: createWithThemeDecorator(Context.Consumer),
        useTheme: () => useContext(Context)
    };
};
export const sum = (...money) => {
    let result = { amount: 0, currency: Currency.USD };
    money.forEach(obj => {
        if (result.amount !== 0) {
            result.amount += obj.amount;
        }
        else {
            result = Object.assign({}, obj);
        }
    });
    return result;
};
export const getBrowserLocale = () => {
    var _a;
    const locale = typeof navigator !== 'undefined'
        ? navigator.languages
            ? navigator.languages[0]
            : (_a = navigator.language) !== null && _a !== void 0 ? _a : navigator['userLanguage']
        : Locale.Russian;
    return locale.split('-')[0].replace(/"/g, '');
};
const defaultResources = {};
let currentLocale = getLocaleFromLocalStorage();
export let currentCurrency = getCurrency();
// No cached locale - pull from local storage.
if (!currentLocale) {
    currentLocale = getLocaleFromLocalStorage();
}
// No cached locale - pull from local storage.
if (!currentCurrency) {
    currentCurrency = getCurrency();
}
// No local storage locale - pull from browser settings.
if (!currentLocale) {
    const browserLocale = getBrowserLocale();
    if (LOCALES.has(browserLocale)) {
        currentLocale = browserLocale;
        setLocaleToLocalStorage(currentLocale);
    }
}
// Load default resources.
LOCALES.forEach(locale => {
    if (!defaultResources[locale]) {
        defaultResources[locale] = {};
    }
    if (!defaultResources[locale].translation) {
        defaultResources[locale].translation = require(`../locales/${locale}/translation.json`);
    }
});
const showKeysPostProcessor = {
    type: 'postProcessor',
    name: 'showKeys',
    process: (value, key, options) => {
        if (!options.ns || (options.ns === 'translation' && key[0].includes(':'))) {
            return `{{${key[0]}}}`;
        }
        return `{{${options.ns}:${key[0]}}}`;
    }
};
const postProcess = [];
if (getShowTranslationKeysFromLocalStorage()) {
    postProcess.push('showKeys');
    const styleNode = document.createElement('style');
    styleNode.innerText = '#root *{text-transform: unset !important}';
    document.head.appendChild(styleNode);
}
i18n.use(showKeysPostProcessor)
    .use(initReactI18next)
    .init({
    resources: defaultResources,
    lng: currentLocale,
    fallbackLng: Locale.Russian,
    keySeparator: false,
    interpolation: {
        escapeValue: false
    },
    postProcess
});
export const getLocale = () => i18n.language;
export const changeLocale = (locale) => __awaiter(void 0, void 0, void 0, function* () {
    if (LOCALES.has(locale)) {
        setLocaleToLocalStorage(locale);
        currentLocale = locale;
        yield i18n.changeLanguage(locale);
    }
});
export const initI18n = memes((moduleName) => {
    LOCALES.forEach(locale => {
        try {
            const resources = require(`../locales/${locale}/${moduleName}.json`);
            i18n.addResourceBundle(locale, moduleName, resources, true, false);
        }
        catch (e) { }
    });
    window['__webskyEngineReady'] = true;
});
export const extendI18n = memes((newResources) => {
    for (const locale in newResources) {
        if (newResources.hasOwnProperty(locale)) {
            for (const moduleName in newResources[locale]) {
                if (newResources[locale].hasOwnProperty(moduleName)) {
                    i18n.addResourceBundle(locale, moduleName, newResources[locale][moduleName], true, true);
                }
            }
        }
    }
});
let userLocation;
export const getUserLocation = (onSuccess, onError) => {
    if (userLocation) {
        onSuccess(userLocation);
    }
    else if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            if (coords.longitude && coords.latitude) {
                userLocation = {
                    longitude: coords.longitude.toString(),
                    latitude: coords.latitude.toString()
                };
                onSuccess(userLocation);
            }
        }, error => onError && onError(error.message));
    }
    else if (onError) {
        onError('GeoLocation API is not available for that kind of browsers');
    }
};
export const format = (date, format, options) => {
    let localeObject;
    switch (currentLocale) {
        case Locale.Russian:
            localeObject = ru;
            break;
        case Locale.English:
            localeObject = enGB;
            break;
        case Locale.Tajik:
            localeObject = tg;
            break;
        case Locale.Georgian:
            localeObject = ka;
            break;
        case Locale.Armenian:
            localeObject = hy;
            break;
        case Locale.German:
            localeObject = de;
            break;
        case Locale.Kazakh:
            localeObject = kk;
            break;
        case Locale.Turkish:
            localeObject = tr;
            break;
        case Locale.Czech:
            localeObject = cs;
            break;
        case Locale.Belarusian:
            localeObject = be;
            break;
    }
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    const formattedDate = originalFormat(parsedDate, format, Object.assign(Object.assign({}, options), { locale: localeObject }));
    return formattedDate.endsWith('.') ? formattedDate.substr(0, formattedDate.length - 1) : formattedDate;
};
export const formatDistance = (date, baseDate, options) => {
    let localeObject;
    switch (currentLocale) {
        case Locale.Russian:
            localeObject = ru;
            break;
        case Locale.English:
            localeObject = enGB;
            break;
        case Locale.Georgian:
            localeObject = ka;
            break;
        case Locale.Armenian:
            localeObject = hy;
            break;
        case Locale.German:
            localeObject = de;
            break;
        case Locale.Kazakh:
            localeObject = kk;
            break;
        case Locale.Turkish:
            localeObject = tr;
            break;
        case Locale.Czech:
            localeObject = cs;
            break;
        case Locale.Belarusian:
            localeObject = be;
            break;
    }
    return originalFormatDistance(date, baseDate, Object.assign(Object.assign({}, options), { locale: localeObject }));
};
export const removeDotsFromDate = (dateString) => {
    return dateString.replace('.', '');
};
export const debounce = (func, wait) => {
    let timeout;
    return function (...newArgs) {
        const later = function () {
            timeout = null;
            func(...newArgs);
        };
        clearTimeout(timeout);
        timeout = window.setTimeout(later, wait);
    };
};
export const mergeCSS = (originalTheme, newTheme) => {
    const result = JSON.parse(JSON.stringify(originalTheme));
    for (const moduleName in newTheme) {
        if (newTheme.hasOwnProperty(moduleName) && originalTheme.hasOwnProperty(moduleName)) {
            const components = newTheme[moduleName];
            for (const componentName in components) {
                if (components.hasOwnProperty(componentName) &&
                    originalTheme[moduleName].hasOwnProperty(componentName)) {
                    const cssClasses = components[componentName];
                    for (const cssClassKey in cssClasses) {
                        if (cssClasses.hasOwnProperty(cssClassKey) &&
                            originalTheme[moduleName][componentName].hasOwnProperty(cssClassKey)) {
                            result[moduleName][componentName][cssClassKey] += ` ${cssClasses[cssClassKey]}`;
                        }
                    }
                }
            }
        }
    }
    return result;
};
export const decapitalizeFirstLetter = memes((string) => {
    return string.charAt(0).toLowerCase() + string.substr(1);
});
const capitalizeFirstLetterSplittedBy = (string, separator) => {
    if (string.includes(separator)) {
        return string
            .split(separator)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(separator);
    }
    return string;
};
export const capitalizeFirstLetter = memes((string) => {
    const lowerString = string.toLowerCase();
    let newString = lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
    newString = capitalizeFirstLetterSplittedBy(newString, ' ');
    return newString;
});
export const capitalizeFirstLetterInName = memes((string) => {
    const newString = capitalizeFirstLetter(string);
    return capitalizeFirstLetterSplittedBy(newString, '-');
});
export const base64ToBlob = (base64, mimeType) => {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: mimeType });
};
export const downloadFile = (base64, name, mimeType) => {
    const blob = base64ToBlob(base64, mimeType);
    const url = URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.href = url;
    element.download = name;
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(url);
};
export const downloadPDF = (base64, name) => {
    downloadFile(base64, name, 'application/pdf');
};
export const downloadPkpass = (base64, name) => {
    downloadFile(base64, name, 'application/vnd.apple.pkpass');
};
export const enableSentry = () => {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_SELF_HOSTED_DSN) {
        Sentry.init({
            dsn: process.env.SENTRY_SELF_HOSTED_DSN,
            // Performance Monitoring
            tracesSampleRate: 0,
            beforeSend(event) {
                // Check if the URL fragment exists
                const locationHash = window.location.hash;
                if (locationHash) {
                    // Add the URL fragment as a tag
                    event.tags = Object.assign(Object.assign({}, event.tags), { locationHash: locationHash });
                }
                return event;
            },
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            allowUrls: [
                'https://static.flyredwings.com/',
                'https://cdn.websky.aero/',
                'https://cdn-eu1.websky.aero/',
                'https://cdn.eu2.websky.tech/',
                'https://cdn.web.websky.tech/',
                'https://static.belavia.by',
                'https://cdn.webskyx.com'
            ]
        });
    }
};
export const captureEventSentry = (event) => {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_SELF_HOSTED_DSN) {
        Sentry.captureMessage(event.detail.message, 'error');
    }
};
export const captureErrorSentry = (message, level = 'error') => {
    if (process.env.NODE_ENV === 'production' && process.env.SENTRY_SELF_HOSTED_DSN) {
        Sentry.captureMessage(message, level);
    }
};
export const removeTrailingSlash = (string) => {
    const stringLength = string.length;
    return string.charAt(stringLength - 1) === '/' ? string.substr(0, stringLength - 1) : string;
};
export const setsAreEqual = (a, b) => {
    if (a.size !== b.size) {
        return false;
    }
    for (const index of a) {
        if (!b.has(index)) {
            return false;
        }
    }
    return true;
};
export const isOdd = (int) => (typeof int === 'string' ? parseInt(int, 10) : int) % 2 === 1;
export const useIsMobile = () => {
    return useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
};
export const useIsDesktop = () => {
    return useMediaQuery({ minWidth: TABLET_MIN_WIDTH });
};
export const scrollTo = (top) => {
    if (window.hasOwnProperty('scrollTo')) {
        window.scrollTo({
            top,
            behavior: 'smooth'
        });
    }
};
const isSeatSegmentsGuard = (segment) => {
    return (segment.hasOwnProperty('isSeatsAvailableInCheckinOnly') ||
        segment.hasOwnProperty('services') ||
        segment.hasOwnProperty('sunSide') ||
        segment.hasOwnProperty('decks'));
};
export const segmentTabFabric = (segment) => {
    if (isSeatSegmentsGuard(segment)) {
        return {
            id: segment.segmentInfo.id,
            title: `${segment.segmentInfo.departure.airport.city.name} — ${segment.segmentInfo.arrival.airport.city.name}`
        };
    }
    return {
        id: segment.id,
        title: `${segment.departure.airport.city.name} — ${segment.arrival.airport.city.name}`
    };
};
export const getPassengerNames = (passenger) => {
    const firstName = getUserValue(passenger, TravellerFieldEnum.FirstName);
    const lastName = getUserValue(passenger, TravellerFieldEnum.LastName);
    const middleName = getUserValue(passenger, TravellerFieldEnum.MiddleName);
    return { firstName, lastName, middleName };
};
export const getPassengerAvatar = (passenger) => {
    const { firstName, lastName } = getPassengerNames(passenger);
    if (firstName || lastName) {
        return [firstName[0], lastName[0]].filter(Boolean).join('').toUpperCase();
    }
    return (parseInt(passenger.id) + 1).toString();
};
initI18n('PassengerTypes');
export const getPassengerTypeLabel = (type, supplierType, count) => {
    const options = { count };
    if (supplierType && i18n.exists(`PassengerTypes:${supplierType}`)) {
        return i18n.t(`PassengerTypes:${supplierType}`, options);
    }
    return i18n.t(`PassengerTypes:${type}`, options);
};
export const concatPassengerNames = (lastName, firstName, middleName) => {
    return [lastName, firstName, middleName].filter(Boolean).map(capitalizeFirstLetterInName).join(' ');
};
export const getPassengerLabel = (passenger) => {
    const { firstName, lastName, middleName } = getPassengerNames(passenger);
    if (firstName && lastName) {
        return concatPassengerNames(lastName, firstName, middleName);
    }
    return getPassengerTypeLabel(passenger.type, passenger.supplierTravellerType);
};
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const seatNumberRegex = /[0-9]{1,4}[A-Z]/;
/**
 * Create a bubbling CustomEvent and dispatch on target.
 *
 * @param target HTMLElement
 * @param event
 */
export const emitEvent = (target, event) => {
    try {
        target.dispatchEvent(new CustomEvent(event.type, {
            detail: event.data,
            bubbles: true
        }));
    }
    catch (e) {
        console.warn('Cannot to emit event ' + event.type);
    }
};
export const isRequireCurrencyParam = () => {
    var _a, _b, _c;
    return ['A9', 'RM', '5G', '2S', 'JI', 'DV', 'B2', 'SZ'].includes((_c = (_b = (_a = window.__webskyEngineConfig) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
};
export const getTravellersMap = memes((travellers) => {
    const map = new Map(), travellerTypesCount = new Map();
    travellers.forEach(traveller => {
        var _a, _b, _c, _d;
        const firstName = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.values) === null || _a === void 0 ? void 0 : _a.find(value => value.type === TravellerFieldEnum.FirstName)) === null || _b === void 0 ? void 0 : _b.value, lastName = (_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.values) === null || _c === void 0 ? void 0 : _c.find(value => value.type === TravellerFieldEnum.LastName)) === null || _d === void 0 ? void 0 : _d.value;
        const fullName = lastName && firstName ? [lastName, firstName].map(capitalizeFirstLetter).join(' ') : null;
        const typeNumber = (travellerTypesCount.get(traveller.type) || 0) + 1;
        map.set(traveller.id, {
            id: traveller.id,
            name: fullName,
            type: traveller.type,
            supplierTravellerType: traveller.supplierTravellerType,
            typeNumber: typeNumber
        });
        travellerTypesCount.set(traveller.type, typeNumber);
    });
    return map;
});
export const getTotalPrice = (items, getItemPrice) => {
    const totalPrice = {
        amount: 0,
        currency: getCurrency()
    };
    if (!!(items === null || items === void 0 ? void 0 : items.length)) {
        return items.reduce((acc, item) => {
            var _a;
            const itemPrice = getItemPrice(item);
            return {
                amount: ((itemPrice === null || itemPrice === void 0 ? void 0 : itemPrice.amount) || 0) + acc.amount,
                currency: (_a = itemPrice === null || itemPrice === void 0 ? void 0 : itemPrice.currency) !== null && _a !== void 0 ? _a : acc.currency
            };
        }, totalPrice);
    }
    return totalPrice;
};
export const getCheckInUrl = (checkInURL, orderId, ticket, lastName, reserveEngines) => {
    const formattedCheckInURL = removeTrailingSlash(checkInURL);
    if ((reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.redirectToWebsky1) && (reserveEngines === null || reserveEngines === void 0 ? void 0 : reserveEngines.checkin)) {
        const checkinURL = removeTrailingSlash(reserveEngines.checkin);
        const locale = convertICULocale(getLocale());
        const url = new URL(checkinURL);
        url.searchParams.set('lang', locale);
        url.searchParams.set('ticketNumber', ticket);
        url.searchParams.set('lastName', lastName);
        url.searchParams.set('submit', '');
        url.hash = 'search';
        return url.toString();
    }
    else if (lastName && ticket) {
        return `${formattedCheckInURL}#/search/${ticket}/${lastName}`;
    }
    return `${formattedCheckInURL}#/${orderId}`;
};
const variationMap = new Map([
    [[1], 0],
    [[2, 3, 4], 1],
    [[0, 5, 6, 7, 8, 9], 2]
]);
export const getI18nCountVariation = (count, variation = variationMap) => {
    var _a, _b;
    const locale = getLocale();
    if (locale === 'ru') {
        const actualCount = count >= 10 && count <= 20 ? 0 : count % 10;
        return (_b = (_a = [...variation.entries()].find(([numbers]) => numbers.includes(actualCount))) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : 0;
    }
    return count === 0 ? 0 : 2;
};
export const getMinPrice = (items, getItemPrice) => {
    if (!!items.length) {
        return items.reduce((minPrice, item) => {
            var _a;
            const price = getItemPrice(item);
            return {
                amount: price.amount < minPrice.amount ? price.amount : minPrice.amount,
                currency: (_a = price === null || price === void 0 ? void 0 : price.currency) !== null && _a !== void 0 ? _a : minPrice.currency
            };
        }, {
            amount: Infinity,
            currency: getCurrency()
        });
    }
    else {
        return {
            amount: 0,
            currency: getCurrency()
        };
    }
};
export const isUnconfirmedService = (status) => {
    return [
        GdsServiceProductStatus.Rejected,
        GdsServiceProductStatus.Canceled,
        GdsServiceProductStatus.Problematic
    ].includes(status);
};
export const getUserValue = (user, type) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = user.values) === null || _a === void 0 ? void 0 : _a.find(value => value.type === type)) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : '';
};
export const getTravellerName = (passenger) => {
    const firstName = getUserValue(passenger, TravellerFieldEnum.FirstName).toLowerCase();
    const lastName = getUserValue(passenger, TravellerFieldEnum.LastName).toLowerCase();
    return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;
};
export const getAutocompleteLabel = (traveller) => {
    const { lastName, firstName, middleName } = getPassengerNames(traveller);
    return concatPassengerNames(lastName, firstName, middleName);
};
export function getFlatArraySet(values) {
    return new Set(values.reduce((result, array) => [...result, ...array], []));
}
export function getSegmentGroupsMap(segmentGroups) {
    const segmentsMap = new Map();
    segmentGroups.forEach(segment => {
        segment.segments.forEach(({ id }) => {
            segmentsMap.set(id, segment.segments);
        });
    });
    return segmentsMap;
}
export const getHasRequestedServices = (travellers, ssrData) => {
    return travellers.some(traveller => {
        var _a, _b, _c, _d, _e;
        const hasRequestedServices = (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => { var _a; return (_a = service.products) === null || _a === void 0 ? void 0 : _a.some(product => (product === null || product === void 0 ? void 0 : product.status) === GdsServiceProductStatus.Requested); });
        const hasRequestedSeats = (_e = (_d = traveller.services) === null || _d === void 0 ? void 0 : _d.seats) === null || _e === void 0 ? void 0 : _e.some(service => { var _a; return ((_a = service.product) === null || _a === void 0 ? void 0 : _a.status) === GdsServiceProductStatus.Requested; });
        const hasRequestedSsrData = ssrData === null || ssrData === void 0 ? void 0 : ssrData.some(ssrData => ssrData.status === SsrStatus.OnRequest);
        return hasRequestedServices || hasRequestedSeats || hasRequestedSsrData;
    });
};
// TODO: remove this when php is fixed
export const convertGenderToCorrectValue = (gender) => {
    var _a;
    const genderMap = {
        M: Gender.Male,
        F: Gender.Female,
        O: Gender.Other
    };
    return (_a = genderMap[gender]) !== null && _a !== void 0 ? _a : gender;
};
export const isFlightFareM2 = (flight) => {
    return (flight === null || flight === void 0 ? void 0 : flight.__typename) === 'FlightFareM2';
};
export const isIncludePricesGroupM2Flight = (priceGroups) => {
    if (!priceGroups || !(priceGroups === null || priceGroups === void 0 ? void 0 : priceGroups.length)) {
        return false;
    }
    return priceGroups.some(group => {
        var _a, _b, _c, _d;
        const flight = (_d = (_c = (_b = (_a = group.prices) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.flight) === null || _c === void 0 ? void 0 : _c.fares) === null || _d === void 0 ? void 0 : _d[0];
        return isFlightFareM2(flight);
    });
};
export const isExistI18nTranslate = (key, options) => {
    return !!i18n.t(key) && i18n.exists(key, options);
};
export const getOffsetTop = (elem) => {
    let offsetTop = 0;
    let currentElement = elem;
    while (currentElement) {
        if (!isNaN(currentElement.offsetTop)) {
            offsetTop += currentElement.offsetTop;
        }
        currentElement = currentElement.offsetParent;
    }
    return offsetTop;
};
export const getRedirectToOrderUrl = (engineURL, orderId) => {
    return `${engineURL}#/booking/${orderId}`;
};
export const redirectToOrder = (engineURL, orderId) => {
    window.open(getRedirectToOrderUrl(engineURL, orderId), '_blank');
};
export const getInsurancesByTypes = (order, insuranceType) => {
    var _a, _b, _c;
    if (!((_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.insurances) === null || _b === void 0 ? void 0 : _b.selectedInsurances) === null || _c === void 0 ? void 0 : _c.length)) {
        return [];
    }
    const selectedInsuranceByCode = new Map();
    order.additionalServices.insurances.selectedInsurances.forEach(insurance => {
        if (insurance.insuranceProgram.type === insuranceType) {
            selectedInsuranceByCode.set(insurance.insuranceProgram.code, insurance.insuranceProgram);
        }
    });
    return [...selectedInsuranceByCode.values()];
};
export const getAllSegmentsServicePrice = (service) => (Object.assign(Object.assign({}, service.price), { amount: service.price.amount * service.allowedSegments.length }));
export const getAppleWalletIconUrl = () => {
    const currentLocale = getLocale();
    return `https://cdn.websky.aero/content/frontend/images/apple-wallet-${currentLocale}.svg`;
};
