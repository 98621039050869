import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import ServiceWithSegments from '../../ServiceWithSegments/ServiceWithSegments';
import ServiceWrapper from './ServiceWrapper/ServiceWrapper';
import { getSegmentsWithMeal } from '../utils';
import { useTheme } from '../../theme';
initI18n('MealService');
const MealService = ({ selectedMeals = [], initialSelectedMeals = [], recommendedMeals, isLoading, mealRenderer, onMobileProceed, onMobileBack, meals, segments, onClose, segmentId }) => {
    const { t } = useTranslation('MealPage');
    const { ServiceWrapper: css } = useTheme('MealService');
    const segmentsWithMeal = getSegmentsWithMeal(meals);
    const firstSegmentWithMeal = segmentId || Array.from(segmentsWithMeal).sort().shift();
    return (React.createElement(ServiceWithSegments, { title: t('Feel the taste in flight'), initialSegmentId: firstSegmentWithMeal, isLoading: isLoading, onMobileProceed: onMobileProceed, onMobileBack: onMobileBack, enabledSegments: segmentsWithMeal, segments: segments.map(segment => ({
            id: segment.id,
            title: `${segment.departure.airport.city.name} — ${segment.arrival.airport.city.name}`,
            isDisabled: !segmentsWithMeal.has(segment.id),
            isChecked: selectedMeals.some(({ segmentId }) => segmentId === segment.id)
        })), onClose: onClose, segmentTabsClassName: css.segmentTabs, segmentTabsWrapperClassName: css.segmentTabs_meal, segmentTabsSegmentsClassName: css.segmentTabs_segments, className: css.meal }, ({ activeSegmentId }) => (React.createElement(ServiceWrapper, { activeSegmentId: activeSegmentId, mealRenderer: mealRenderer, meals: meals, selectedMeals: selectedMeals, recommendedMeals: recommendedMeals }))));
};
export default MealService;
