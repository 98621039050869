import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import SearchForm from '../../SearchForm';
import Checkout from '../../Checkout';
import ResultsError from '../../ResultsError';
import { DelayedPromoLoader } from '../../PromoLoader';
import FastSearch from './FastSearch/FastSearch';
import MetaRedirect from './MetaRedirect/MetaRedirect';
import { useConfig } from '../../context';
import FindOrder from '../../Checkout/components/Checkout/FindOrder/FindOrder';
import Page404 from '../../Page404/Page404';
import CreateOrder from './CreateOrder/CreateOrder';
import { API_DATE_FORMAT, format, initI18n, isRequireCurrencyParam } from '../../utils';
import FareLock from '../../FareLock/';
import { useFareLock } from '../../FareLock/hooks';
import RestoreOrder from './RestoreOrder/RestoreOrder';
import { useTheme } from '../../theme';
import { OverrideComponent } from '../../renderProps';
import Schedule from '../../Schedule';
import { useRunSearchMutation, useSearchResultsQuery } from '@websky/graphql';
import { useSchedule } from '../../Schedule/hooks';
import { CURRENCY_KEY, set } from '../../cache';
import getOrderDataLayer, { createDataLayer } from '../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../DataLayerAnalytics/OrderDataLayer/types';
import { initTagManager } from '../../DataLayerAnalytics/tagManager';
import { ResultsWrapper } from '../../Results/components/ResultsWrapper/ResultsWrapper';
import { extractPromo, hasPromo } from './FastSearch/utils';
import { ApolloError } from 'apollo-boost';
import { EmptySearchResultSegmentsError } from './errors';
import { useCurrentUser } from '../../CurrentUser/CurrentUser.hooks';
createDataLayer(OrderType.SearchResults);
initI18n('Title');
const Inner = ({ history }) => {
    useCurrentUser();
    const { t } = useTranslation();
    const { Inner: css } = useTheme('Inner');
    const [isRedirectFromMeta, setIsRedirectFromMeta] = useState(false);
    const [order, setOrder] = useState(null);
    const [results, setResults] = useState();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const { Engine: { searchFormURL }, global: { companyInfo: { iataCode }, allowPromoCodes } } = useConfig();
    const [searchMutation] = useRunSearchMutation();
    const resultsQuery = useSearchResultsQuery({ fetchPolicy: 'no-cache', skip: true });
    const [promoCode, setPromoCode] = useState(undefined);
    React.useEffect(() => {
        initTagManager();
    }, []);
    const handleOrderLoaded = useCallback((order) => {
        setOrder(order);
    }, [order, setOrder]);
    const orderLoadingHandler = useCallback(orderLoading => {
        setOrderLoading(orderLoading);
    }, []);
    const { price, handleChangeFareLock, isSelected, fareLockService, loading } = useFareLock(order, handleOrderLoaded);
    const handleRenderFareLock = useCallback(() => {
        if (order && fareLockService && !orderLoading) {
            getOrderDataLayer(OrderType.Checkout).setOrder(order);
            return (React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, isActive: isSelected, onChange: handleChangeFareLock }));
        }
        return null;
    }, [price, isSelected, handleChangeFareLock, order]);
    const [getSchedule, { schedule, defaultParameters, availableDates, isLoading: scheduleLoading }] = useSchedule();
    const dismissMetaWarning = useCallback(() => {
        setIsRedirectFromMeta(false);
    }, []);
    const onError = useCallback((e) => {
        var _a, _b;
        let error = new Error();
        error.name = t('It looks like something went wrong');
        error.message = t('Please reload the page or start a new search');
        if (e instanceof ApolloError && ((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.originalError)) {
            error = e.graphQLErrors[0].originalError;
        }
        else if (e instanceof Error) {
            error = e;
        }
        setError(error);
        console.error(e);
    }, [setError]);
    const loadResults = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        setOrder(null);
        setIsLoading(true);
        try {
            const { data } = yield resultsQuery.refetch({ id });
            if (!((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.SearchResult) === null || _a === void 0 ? void 0 : _a.searchParameters) === null || _b === void 0 ? void 0 : _b.segments) === null || _c === void 0 ? void 0 : _c.length)) {
                throw new EmptySearchResultSegmentsError();
            }
            setResults((_d = data === null || data === void 0 ? void 0 : data.SearchResult) !== null && _d !== void 0 ? _d : null);
        }
        catch (error) {
            onError(error);
        }
        setIsLoading(false);
    }), [resultsQuery.refetch, setOrder, setIsLoading, onError]);
    const startSearch = useCallback((params, replace = true) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setError(null);
        if (params.currency) {
            set(CURRENCY_KEY, params.currency);
        }
        if (replace) {
            history.replace('/results');
        }
        else {
            history.push('/results');
        }
        try {
            const data = yield searchMutation({
                variables: {
                    params: Object.assign(Object.assign({}, params), { currency: isRequireCurrencyParam() ? params.currency : undefined })
                }
            });
            if (data.errors) {
                throw new Error(data.errors[0].message);
            }
            else if (data.data.RunGeneralSearch && data.data.RunGeneralSearch.id) {
                if (replace) {
                    history.push('/results/' + data.data.RunGeneralSearch.id);
                }
                else {
                    history.replace('/results/' + data.data.RunGeneralSearch.id);
                }
                loadResults(data.data.RunGeneralSearch.id);
            }
            else {
                throw new Error('No results ID');
            }
        }
        catch (error) {
            setError({
                name: t('It looks like something went wrong'),
                message: `${t('Please reload the page or start a new search')}`
            });
            console.error(error);
            setIsLoading(false);
        }
    }), []);
    const onOrderRecieved = (order, replace = false) => {
        if (order) {
            setOrder(order);
        }
        history.push(`/booking/${order.id}`);
    };
    const redirectToPayment = (order) => {
        if (order) {
            setOrder(order);
            history.push(`/booking/${order.id}/payment`);
        }
    };
    const onMetasearchResultsRedirect = (resultsId) => {
        setIsRedirectFromMeta(true);
        history.replace('/results/' + resultsId);
    };
    const onScheduleSearch = useCallback((parameters, date, reload) => {
        if (date) {
            getSchedule(parameters, date);
        }
        else {
            const path = `/schedule/${parameters.departure}/${parameters.arrival}`;
            if (history.location.pathname !== path) {
                history.push(path);
                if (reload) {
                    window.location.reload();
                }
            }
        }
    }, [history, getSchedule]);
    const isScheduleAvailable = ['D2', 'ZZ', 'YC', 'DV', 'KV', 'Y7'].includes(iataCode);
    return (React.createElement("div", { style: { flexBasis: '100%' }, className: css.inner },
        React.createElement(Helmet, null,
            React.createElement("title", null,
                t('Title:companyName'),
                " \u2013 ",
                t('Title:Find tickets'))),
        React.createElement(DelayedPromoLoader, { loading: isLoading || scheduleLoading },
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/", exact: true, render: () => {
                        if (searchFormURL) {
                            window.location.href = searchFormURL;
                            return null;
                        }
                        return (React.createElement(OverrideComponent, { componentProps: { onSubmit: startSearch, promoCode }, component: { SearchForm } }));
                    } }),
                React.createElement(Route, { path: "/search/:departure/:arrival", exact: true, render: routeProps => (React.createElement(OverrideComponent, { componentProps: {
                            key: routeProps.location.pathname,
                            onSubmit: startSearch,
                            defaultParameters: {
                                currency: null,
                                segments: [
                                    {
                                        arrival: {
                                            iata: routeProps.match.params['arrival'],
                                            city: null,
                                            name: null
                                        },
                                        departure: {
                                            iata: routeProps.match.params['departure'],
                                            city: null,
                                            name: null
                                        },
                                        date: format(new Date(), API_DATE_FORMAT)
                                    }
                                ],
                                passengers: null,
                                promotionCode: null,
                                ffpMode: null
                            }
                        }, component: { SearchForm } })) }),
                React.createElement(Route, { path: "/restore/:orderId/:token", exact: true, render: () => (React.createElement(RestoreOrder, { onOrderReceived: onOrderRecieved })) }),
                React.createElement(Route, { path: ['/find/:id?/:secret?', '/find/:id?/:secret?/submit'], exact: true, render: routeProps => {
                        if (routeProps.match.path.includes('submit')) {
                            return React.createElement(FindOrder, { onSuccess: onOrderRecieved, callSubmit: true });
                        }
                        else {
                            return React.createElement(FindOrder, { onSuccess: onOrderRecieved });
                        }
                    } }),
                React.createElement(Route, { path: ['/payment/:id?/:secret?', '/payment/:id?/:secret?/submit'], exact: true, render: routeProps => {
                        const callSubmit = routeProps.match.path.includes('submit');
                        return React.createElement(FindOrder, { onSuccess: redirectToPayment, callSubmit: callSubmit });
                    } }),
                React.createElement(Route, { path: "/metasearch/:token", exact: true, render: () => (React.createElement(MetaRedirect, { onResultsRedirect: onMetasearchResultsRedirect, onSuccess: onOrderRecieved })) }),
                React.createElement(Route, { path: "/results", exact: true, render: () => {
                        if (error) {
                            return (React.createElement(React.Fragment, null,
                                React.createElement(OverrideComponent, { componentProps: { onSubmit: startSearch }, component: { SearchForm } }),
                                React.createElement(ResultsError, { header: error.name, body: error.message })));
                        }
                        return !error && !isLoading ? React.createElement(Redirect, { to: "/" }) : null;
                    } }),
                React.createElement(Route, { path: "/search/:fastSearch", exact: true, render: props => (React.createElement(OverrideComponent, { componentProps: {
                            startSearch,
                            fastSearch: props.match.params.fastSearch,
                            redirect: React.createElement(Redirect, { to: "/" })
                        }, component: { FastSearch } })) }),
                React.createElement(Route, { path: "/results/create-order/:flightIds+", render: props => (React.createElement(CreateOrder, { flightsIds: props.match.params.flightIds, redirectOnFail: React.createElement(Redirect, { to: '/' }), onSuccess: onOrderRecieved })) }),
                React.createElement(Route, { path: "/results/:id(\\d+/?)+", render: props => {
                        return (React.createElement(ResultsWrapper, { order: order, loading: loading, renderFareLock: handleRenderFareLock, showMetaWarning: isRedirectFromMeta, onMetaWarningDismiss: dismissMetaWarning, onOrderLoaded: handleOrderLoaded, onOrderLoading: orderLoadingHandler, searchResults: results, errors: error ? [error] : null, startNewSearch: startSearch, onProceed: onOrderRecieved, resultId: props.match.params.id, loadResults: loadResults, isLoading: isLoading, error: error }));
                    } }),
                React.createElement(Route, { path: "/booking/:id(\\d+/?)+", render: props => (React.createElement(React.Fragment, null,
                        React.createElement(Helmet, null,
                            React.createElement("title", null,
                                t('Title:companyName'),
                                " \u2013 ",
                                t('Title:Checkout order'))),
                        React.createElement(Checkout, { startNewSearch: startSearch, orderId: props.match.params.id, order: order }))) }),
                isScheduleAvailable && (React.createElement(Route, { path: "/schedule", exact: true, render: () => {
                        if (scheduleLoading) {
                            return null;
                        }
                        return React.createElement(Schedule, { onScheduleSearch: onScheduleSearch });
                    } })),
                isScheduleAvailable && (React.createElement(Route, { path: "/schedule/:departure([A-Z]{3,3})/:arrival([A-Z]{3,3})", render: props => {
                        var _a;
                        const params = props.match.params;
                        const segment = (_a = defaultParameters === null || defaultParameters === void 0 ? void 0 : defaultParameters.segments) === null || _a === void 0 ? void 0 : _a[0];
                        const isOtherCities = segment &&
                            (segment.departure.iata !== params.departure ||
                                segment.arrival.iata !== params.arrival);
                        if ((!isLoading && !scheduleLoading && !schedule) || isOtherCities) {
                            getSchedule({ departure: params.departure, arrival: params.arrival });
                            return null;
                        }
                        if (scheduleLoading) {
                            return null;
                        }
                        return (React.createElement(Schedule, { defaultParameters: defaultParameters, schedule: schedule, availableDates: availableDates, onStartSearch: startSearch, onScheduleSearch: onScheduleSearch }));
                    } })),
                React.createElement(Route, { path: "/:fastSearch", exact: true, render: props => {
                        const searchParams = props.match.params.fastSearch;
                        if (allowPromoCodes && hasPromo(searchParams)) {
                            setPromoCode(extractPromo(searchParams));
                        }
                        return React.createElement(Redirect, { to: "/" });
                    } }),
                React.createElement(Page404, { onClick: () => (window.location.href = '/') })))));
};
export default withRouter(Inner);
