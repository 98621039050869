import * as React from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { isAfter, parse } from 'date-fns';
import cn from 'classnames';
import { Select } from '../Select/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { concatPassengerNames } from '../../../../utils';
import { useTheme } from '../../../../theme';
export const LinkedTraveller = props => {
    var _a;
    const { t } = useTranslation('Passenger');
    const theme = useTheme('Passenger').LinkedTraveller;
    const formState = useFormState();
    const options = props.validations[0].options.map(option => {
        return {
            key: option.label,
            value: option.value
        };
    });
    const valueRenderer = React.useCallback(value => {
        var _a, _b, _c;
        const passenger = formState.values.passengers[value];
        let name = `${t('Adult')} ${parseInt(value, 10) + 1}`;
        if (((_a = passenger.firstName) === null || _a === void 0 ? void 0 : _a.trim()) && ((_b = passenger.lastName) === null || _b === void 0 ? void 0 : _b.trim())) {
            name = concatPassengerNames(passenger.lastName.trim(), passenger.firstName.trim(), (_c = passenger.middleName) === null || _c === void 0 ? void 0 : _c.trim());
        }
        return name;
    }, [props.value, formState.values.passengers]);
    const checkAdultAge = () => {
        var _a;
        const passenger = formState.values.passengers[props.value];
        const birthDate = ((_a = passenger === null || passenger === void 0 ? void 0 : passenger.birthDate) === null || _a === void 0 ? void 0 : _a.length) === 10 ? parse(passenger.birthDate, 'dd.MM.yyyy', new Date()) : null;
        const minDate = props.validations[0].minDate
            ? new Date(new Date(props.validations[0].minDate).setHours(0, 0, 0, 0))
            : null;
        if (birthDate && minDate && isAfter(birthDate, minDate)) {
            return t('The chaperone must be over {{count}} years of age', { count: 18 });
        }
        return null;
    };
    const adultAgeError = checkAdultAge();
    return (React.createElement(Select, Object.assign({}, props, { classes: Object.assign(Object.assign({}, props.classes), { input_error: cn(theme.inputError, (_a = props.classes) === null || _a === void 0 ? void 0 : _a.input_error) }), renderValue: valueRenderer, renderOption: option => {
            return (React.createElement(MenuItem, { key: option.key, value: option.key }, valueRenderer(option.value)));
        }, readonly: options.length === 1, value: props.value, options: options, name: props.name, label: props.label, className: props.className, onChange: props.onChange, onBlur: props.onBlur, error: adultAgeError !== null && adultAgeError !== void 0 ? adultAgeError : props.error, disabled: props.disabled, selectFirstOptionByDefault: true })));
};
