import * as React from 'react';
import { useTheme } from '../../../theme';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Add } from '../../../Icons';
import Money from '../../../Money';
import { Button } from '../../../index';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
const AggregatorService = props => {
    var _a, _b, _c, _d, _e;
    const theme = useTheme('CheckoutAdditionalService').AggregatorService;
    const { t } = useTranslation('CheckoutAdditionalService');
    const onDescriptionClickHandler = (e) => {
        var _a;
        if (((_a = e.target) === null || _a === void 0 ? void 0 : _a.tagName) === 'A') {
            e.stopPropagation();
        }
    };
    const defaultRenderPrice = () => {
        var _a;
        return (((_a = props.priceTotal) === null || _a === void 0 ? void 0 : _a.amount) > 0 && (React.createElement(React.Fragment, null,
            t('Total'),
            ": ",
            React.createElement(Money, { money: props.priceTotal }))));
    };
    return (React.createElement("div", { className: cn(theme.container, props.className, {
            [theme.container_active]: !props.isSelected
        }), onClick: !props.isSelected ? props.addClick : () => { } },
        React.createElement("div", { className: cn(theme.cover, props.coverClassName), style: { backgroundImage: props.coverBackgroundUrl && `url(${props.coverBackgroundUrl})` } },
            !props.isSelected && (React.createElement(ButtonBase, { onClick: props.addClick, className: theme.add, "aria-hidden": "false" }, Add)),
            props.isSelected && (React.createElement("div", { className: cn(theme.checked, { [theme.checked_removable]: !!props.onDelete }) })),
            !!((_a = props.aggregationIcons) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement("div", { className: theme.icons }, props.aggregationIcons.map((icon, key) => (React.createElement(React.Fragment, { key: key }, icon)))))),
        React.createElement("div", { className: theme.content },
            React.createElement("div", { className: theme.header }, props.header),
            props.isSelected ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: theme.content__services }, props.children),
                React.createElement("div", { className: theme.total },
                    React.createElement("div", { className: theme.totalPrice }, (_c = (_b = props.renderHeaderPrice) === null || _b === void 0 ? void 0 : _b.call(props)) !== null && _c !== void 0 ? _c : defaultRenderPrice()),
                    React.createElement("div", { className: theme.buttons }, (_d = props.buttons) === null || _d === void 0 ? void 0 : _d.map((button, key) => {
                        return (React.createElement(Button, { key: key, onClick: button.action, variant: "text" }, button.text));
                    }))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: cn(theme.description, props.descriptionClassName) },
                    props.discount && React.createElement("div", { className: theme.discount }, props.discount),
                    React.isValidElement(props.description) && props.description,
                    !React.isValidElement(props.description) && (React.createElement("div", { onClick: onDescriptionClickHandler, dangerouslySetInnerHTML: { __html: props.description } }))),
                ((_e = props.priceFrom) === null || _e === void 0 ? void 0 : _e.amount) >= 0 && (React.createElement("div", { className: theme.total },
                    React.createElement("div", { className: theme.totalPrice },
                        t('from'),
                        " ",
                        React.createElement(Money, { money: props.priceFrom })))))))));
};
export default AggregatorService;
